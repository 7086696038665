<template>
  <ul class="Breadcrumbs flex lg:justify-start overflow-auto h-6 px-5 md:px-0">
    <li v-for="(breadcrumb, idx) in items" :key="idx" class="flex-self-start flex items-center relative">
      <component
        :is="
          breadcrumb.path === $route.path ||
          `/ar${breadcrumb.path}` === $route.path ||
          `/en${breadcrumb.path}` === $route.path
            ? 'span'
            : 'AppLink'
        "
        :to="`${breadcrumb.path}`"
        class="whitespace-nowrap flex items-center justify-center"
        :class="{
          'decoration-transparent': breadcrumb.path === $route.path,
        }"
      >
        {{ breadcrumb.label }}
      </component>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { type WithContext, type BreadcrumbList } from 'schema-dts';

const {
  public: { appUrl },
} = useRuntimeConfig();
defineComponent({
  name: 'Breadcrumb',
});

const props = defineProps({
  items: {
    type: Array as PropType<
      {
        path: string;
        label: string;
      }[]
    >,
    required: true,
  },
});

useJsonld(
  computed<WithContext<BreadcrumbList>>(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',

      itemListElement: props.items?.map((i: any, idx) => {
        return {
          '@type': 'ListItem',
          name: i.label,
          item: `${appUrl}${i.path}`,
          position: idx + 1,
        };
      }),
    };
  }).value,
);
</script>

<style lang="postcss" scoped>
.Breadcrumbs {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

li {
  @apply text-current font-body underline;

  &:last-child {
    @apply text-gray-500 font-body font-normal;
    text-decoration-line: none;
  }
}

li + li {
  @apply ml-2;

  @screen lg {
    @apply pl-0;
  }
}
</style>
